import { MODULOS_DESCRICAO } from 'src/utils/modulos';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const TIPO_MATRIZ_RISCO = {
  RISCO: 1,
  ITENS_IMPACTADOS: 2,
};

export const ENTIDADES_CUSTOMIZADAS = {
  PONTO_CONTROLE: 101,
  PONTO_CONTROLE_REVISAO: 105,
  EVENTOS_RISCO: 106,
  PLANOS_CONTIGENCIA: 107,
  HISTORICO_CARGO_COLABORADOR: 108,
  PROJETO_STATUS_REPORT: 200,
  AUDITORIA_RISCO: 201,
  CONTROL_SELF_ASSESSMENT: 202,
  CONTROL_SELF_ASSESSMENT_TEMPLATE: 203,
  CONTROL_SELF_ASSESSMENT_EXECUCAO: 204,
  PLANO_AUDITORIA: 205,
  GESTAO_DOCUMENTOS: 206,
  PONTO_AUDITORIA: 207,
};

/* Mesmo ID do backend (Domain.FlyWeight/Filtro.cs) */
export const FILTROS = {
  /*SEED */
  RELATORIOBSC: 66,
  ACAO_TAREFA: 68,
  /*GESTAO RISCO */
  PONTO_CONTROLE: 313,
  REVISOES_PENDENTES: 314,
  VALIDACOES_REVISOES_PONTO_CONTROLE: 315,
  REVISOES_PONTO_CONTROLE: 316,
  AVALIACOES_PENDENTES: 317,
  RISCO_GRAFICOSCORE: 323,
  REVISOES_RISCO_PONTO_CONTROLE: 326,
  PLANOS_CONTIGENCIA: 334,
  EVENTO_RISCO: 335,
  MODELO_CHECKLIST: 340,
  AUDITORIA_RISCO: 343,
  GESTAO_MUDANCAS_RISCO: 345,
  CONTROL_SELF_ASSESSMENT: 346,
  PLANO_AUDITORIA: 347,
  PONTO_AUDITORIA: 348,
  TESTE_CONTROLE: 349,
  /* AVALIACAO DE DESEMPENHO */
  COLABORADOR_HISTORICO_OCUPACAO: 401,
  COLABORADOR_HISTORICO_OCUPACAO_CARGO: 402,
  MODELO_AVALIACAO: 403,
  GRUPO_AVALIADOS: 404,
  ELEMENTO_MODELO_AVALIACAO: 405,
  MODELO_ESCALA: 406,
  CICLO_AVALIACAO: 407,
  CICLO_AVALIACAO_PROCESSO_AVALIATIVO: 408,
  PDI: 409,
  REUNIAO_GENTE: 410,
  ONEAONE: 411,
  BLOCO_NOTAS: 412,
  LIFTBOX: 413,
  MAPA_SUCESSAO_PESSOAS: 414,
  MAPA_SUCESSAO_POSICOES: 415,
  META_INDIVIDUAL: 416,
  FEEDBACK_CONTINUO: 417,
  CICLO_META_INDIVIDUAL: 418,
  //Objetivos
  OKR: 501,
  CHAVERESULTADO_INICIATIVA: 502,
  // ESCRITÓRIO DE PROJETOS
  PROJETO_STATUS_REPORT: 600,
  ACAO_APROVACAO: 604,
  APROVAR_ACOES: 605,
  CRITERIOS_PRIORIZACAO: 606,
  PORTFOLIO_PROJETOS: 607,
  FLUXO_APROVACAO_GENERICO: 608,
  GESTAO_MUDANCAS_PROJETO: 609,
  MATRIZ_RESPONSABILIDADES_ATIVIDADE: 610,
  MATRIZ_RESPONSABILIDADES_RESPONSABILIDADE: 611,
  GESTAO_MUDANCAS_INDICADOR: 612,
  GESTAO_MUDANCAS_PARAMETRO: 613,
  GESTAO_MUDANCAS_OBJETIVOESTRATEGICO: 614,
  CARD_METAS: 312,
  LOG_INTEGRACAO_API_COLABORADOR: 61,
  LOG_INTEGRACAO_API_HISTORICO_CARGO: 62,
  LOG_INTEGRACAO_API_CARGO: 63,
  LOG_INTEGRACAO_API_ORGANIZACAO: 64,
  LOG_INTEGRACAO_API_VALOR_REALIZADO: 65,
  PLANOCONTAS_PROJETO: 615,
  // RV
  MODELO_VISUALIZACAO_COMISSOES: 337,
  DRILLDOWN_COMISSOES: 205,
  WEBHOOK: 206,
  WEBHOOK_LOG: 207,
  WEBHOOK_LOG_REQUISICAO: 208,
  DETALHAMENTO_PROVISOES: 209,
  DOCUMENTO: 701,
  DOCUMENTOVERSIONAMENTOMANUAL: 702,
  GESTAO_MUDANCAS_DOCUMENTO: 703,
  FUNCOES: 216,
};

/* Mesmo ID do backend (Portal.Web.Info.TagMenu) */
export const COLUNAS = {
  AVALIACAO: 12001,
  RISCO_REVISOES_PONTO_CONTROLE: 12002,
  PONTO_CONTROLE: 12003,
  REVISOES_PENDENTES: 12004,
  VALIDACOES_REVISOES_PONTO_CONTROLE: 12005,
  REVISOES_PONTO_CONTROLE: 12006,
  AVALIACAO_PENDENTE: 12007,
  RISCO_PONTO_CONTROLE: 12022,
  INDICADOR_RISCO: 12024,
  PLANOS_CONTIGENCIA: 12025,
  EVENTO_RISCO: 12026,
  MODELO_CHECKLIST: 12027,
  AUDITORIA_RISCO: 12028,
  CONTROL_SELF_ASSESSMENT: 12029,
  RESULTADO_AUDITORIA: 12030,
  PONTO_AUDITORIA: 12031,
  TESTE_CONTROLE: 12032,
  NIVEL_PROFICIENCIA: 13001,
  NIVEL_EDUCACIONAL: 13002,
  LOCALIDADE: 13003,
  ORIENTACAO_SEXUAL: 13004,
  RACA: 13005,
  MODELO_AVALIACAO: 13006,
  GRUPO_AVALIADOS: 13007,
  ELEMENTO_MODELO_AVALIACAO: 13008,
  LINGUA: 13009,
  MODELO_ESCALA: 13010,
  CICLO_AVALIACAO: 13011,
  REUNIAO_GENTE: 13012,
  RESULTADOS_REUNIAO_GENTE: 13013,
  RESULTADOS_MEU_DESEMPENHO: 13014,
  RESULTADOS_CICLO_AVALIACAO: 13015,
  RESULTADOS_DESEMPENHO_TIME: 13016,
  CRITICIDADE_CARGO: 13017,
  FAIXA_TEMPO_CARGO: 13018,
  FAIXA_TEMPO_EMPRESA: 13019,
  FAIXA_TEMPO_LIDER_CARGO: 13020,
  FAIXA_IDADE: 13021,
  CICLO_META_INDIVIDUAL: 13022,
  BIBLIOTECA_ACOES_PDI: 13023,
  PORTFOLIO_PROJETOS: 15001,
  SUBPROJETOS_RESUMO_PROJETO: 15002,
  RELACIONAMENTOS_RESUMO_PROJETO: 15003,
  PROJETO_STATUS_REPORT: 15004,
  VALORES_INDICADORES_DE_PROJETO: 15005,
  PROJETOS_FLUXO_APROVACAO: 15006,
  APROVAR_ACOES: 15008,
  FLUXO_APROVACAO_GENERICO: 15009,
  GESTAO_MUDANCAS_PROJETO: 15010,
  GESTAO_MUDANCAS_INDICADOR: 15011,
  GESTAO_MUDANCAS_PARAMETRO: 15012,
  GESTAO_MUDANCAS_OBJETIVOESTRATEGICO: 15013,
  GESTAO_MUDANCAS_RISCO: 15014,
  GESTAO_MUDANCAS_DOCUMENTO: 1501,
  CARD_METAS: 11004,
  LOG_INTEGRACAO_API_COLABORADOR: 5010,
  LOG_INTEGRACAO_API_HISTORICO_CARGO: 5011,
  LOG_INTEGRACAO_API_CARGO: 5012,
  LOG_INTEGRACAO_API_ORGANIZACAO: 5013,
  LOG_INTEGRACAO_API_VALOR_REALIZADO: 5014,
  CESTA_INDICADOR_BONUS: 10008,
  CESTA_INDICADOR_COMISSAO: 10008,
  RELATORIOBSC: 3015,
  ACAO_TAREFA: 5011,
  CONTRATORESULTADOS: 11007,
  RELATORIO_STATUS_REPORT: 15014,
  PLANOCONTAS_PROJETO: 15015,
  PLANOCONTAS_PROJETO_VALOR: 15016,
  DOCUMENTO: 16001,
  DOCUMENTOVERSIONAMENTOMANUAL: 16002,
  CHAVERESULTADO_INICIATIVA: 17001,
  CESTA_INDICADOR_ITENS: 11013,
};

/* Mesmo ID do backend (Domain.Apresentacao.TipoObjeto) */
export const APRESENTACAO = {
  RISCO: 'Risco',
  PONTOCONTROLE: 'PontoControle',
  REVISOESPONTOCONTROLE: 'RevisoesPontoControle',
  MATRIZRISCO: 'MatrizRisco',
  CALENDARIO: 'Calendario',
  RESULTADOITEM: 'ResultadoItem',
};

export const WIDGET_CUSTOM = {
  INDICADOR: 1,
  PARAMETRO: 2,
  OBJETIVO: 3,
  GRAFICOCOMPARATIVO: 4,
  GRAFICODESVIO: 5,
  GRAFICO: 6,
  GRAFICOACOES: 7,
  PROJETO: 8,
  ACAO: 9,
  GRAFICOINDICADORES: 10,
  GRAFICOPACOTECONTA: 11,
  PACOTECENTROCUSTO: 12,
  GRAFICOVELOCIMETRO: 13,
  RISCO: 14,
  GRAFICOESTATISTICAS: 17,
  GRAFICOGANHOSPORACAO: 18,
  GRAFICODESVIOSPORACAO: 19,
  TABELAVALORES: 20,
  GRAFICOPIZZAMULTINIVEL: 21,
  STATUSREPORT: 22,
  ADERENCIAAOMETODO: 23,
  GRAFICOCURVAS: 24,
  RELATORIOACOMPANHAMENTO: 25,
  REVISOESPONTOCONTROLE: 26,
  QUANTITATIVOREVISOESPONTOCONTROLE: 27,
  CALENDARIO: 28,
  QUANTITATIVORISCOS: 29,
  CARDMETAS: 30,
  POSTIT: 31,
  ISHIKAWA: 32,
  ANEXO: 100,
  CIRCULO: 101,
  RETANGULO: 102,
  IMAGEM: 103,
  TEXTO: 104,
  LINK: 105,
  CAMPOCOMENTARIO: 106,
  MATRIZRISCO: 107,
  OKR: 108,
  EVOLUCAOSCORERISCO: 109,
  TABELA_PROJETOS: 110,
  TABELA_ACOES: 111,
  SCORE: 112,
  W5PORQUES: 113,
  MATRIZGUT: 114,
  IMPACTO_FINANCEIRO_INERENTE: 115,
  IMPACTO_FINANCEIRO_RESIDUAL: 116,
  IMPACTO_FINANCEIRO_REDUCAO: 117,
  SHAREPOINT: 118,
  QUANTITATIVOPROJETOS: 119,
  PENDENCIASRESPONSABILIDADES: 120,
  INDICADORESFAVORITOS: 121,
  DASHBOARDSFAVORITOS: 122,
  ESTATISTICAS: 123,
  QUANTITATIVO_KRS: 124,
  DESEMPENHOOKRS: 125,
  OKRSPORFAROL: 126,
  ORCAMENTO_PROJETOS_VALOR: 127,
  ORCAMENTO_PROJETOS_GRAFICO: 128,
  RISCO_RANKING: 129,
  LINKDASHBOARD: 130,
  INDICADORES_RISCO: 131,
  DETALHAMENTO_PLANO_CONTAS: 132,
  DESEMPENHO_PROJETO: 133,
  TABELA_INICIATIVAS: 134,
  COMENTARIOS_ITEM: 135,
};

export const REUNIAO = {
  PENDENTE: 1,
  CONFIRMADO: 2,
  RECUSADO: 3,
};

/* Mesmo ID do backend (Domain.FlyWeight.TipoEventoCalendario) */
export const EVENTOS_CALENDARIO = {
  INICIOACOES: { id: 1, icone: 'clock-start' },
  TERMINOACOES: { id: 2, icone: 'clock-end' },
  VALORESDESATUALIZADOS: { id: 3, icone: 'numeric' },
  REUNIOES: { id: 4, icone: 'calendar' },
  REVISOESPONTOCONTROLE: { id: 5, icone: 'alpha-p-box' },
  AVALIACOESRISCO: { id: 6, icone: 'alpha-r-box' },
};

export const TIPO_EXIBICAO_CALENDARIO = {
  DIA: 'day',
  SEMANA: 'week',
  MES: 'month',
  AGENDA: 'agenda',
};

export const STATUS_LOG = {
  SUCESSO: 3,
  AVISO: 5,
  ERRO: 4,
};

export const COLABORADOR_CAMPOS = {
  EMAIL: 1,
  TELEFONE: 2,
  CELULAR: 3,
  LINKEDIN: 4,
  GENERO: 5,
  NOME_SOCIAL: 6,
  RACA: 7,
  ORIENTACAO_SEXUAL: 8,
  IDIOMAS: 9,
  CURSO_EXTRACURRICULAR: 10,
  FORMACAO_ACADEMICA: 11,
  DISPONIBILIDADE_MUDANCA: 12,
  INTERESSE_OUTRAS_AREAS: 13,
  AUTORIZACAO_DIVULGACAO_ANIVERSARIO: 14,
  CARGO: 15,
  EXPERIENCIASANTERIORES: 16,
};

export const MODULOS = {
  GESTAO_ESTRATEGICA: 1,
  MATRIZ_ORCAMENTARIA: 2,
  REMUNERACAO_VARIAVEL: 3,
  AVALIACAO_DESEMPENHO: 4,
  GESTAO_RISCO: 5,
  ESCRITORIO_PROJETOS: 7,
  INTELIGENCIA_ARTIFICIAL: 8,
};

export const MODULOS_COMPLETO = MODULOS_DESCRICAO;

export const TIPO_ELEMENTO_FORMULARIO = {
  GERAL: 'geral',
  SECAO: 'secao',
  SUBSECAO: 'subsecao',
  PERGUNTA: 'pergunta',
  TITULO: 'titulo',
  PARAGRAFO: 'paragrafo',
  IMAGEM: 'imagem',
  PERGUNTA_AVALIACAO_MULTIPLA: 'pergunta-interna',
};

export const PROCESSO_AVALIATIVO_TIPO_VISAO = {
  LIST: 1,
  EDIT: 2,
};

export const STATUS_PROCESSO_AVALIATIVO = {
  RASCUNHO: 0,
  PUBLICADO: 1,
  ATIVO: 2,
  CONCLUIDO: 3,
  CANCELADO: 99,
};

/* namespace Stratec.Domain.AvaliacaoDesempenho.TipoAvaliacaoEnum */
export const TIPO_AVALIACAO = {
  CONSOLIDADO: 0,
  AUTO_AVALIACAO: 1,
  LIDER: 2,
  LIDERADO: 3,
  PARES: 4,
  GESTOR_MATRICIAL: 5,
};

export const STATUS_PROCESSO_AVALIATIVO_AVALIACAO = {
  PROGRAMADA: 1,
  A_INICIAR: 2,
  ATRASADA: 3,
  EM_ANDAMENTO: 4,
  CONCLUIDA: 5,
};

export const STATUS_PROCESSO_AVALIATIVO_FEEDBACK = {
  PROGRAMADO: 1,
  A_INICIAR: 2,
  ATRASADO: 3,
  CONCLUIDO: 4,
};

export const STATUS_AUDITORIA = {
  RASCUNHO: 0,
  ATIVO: 1,
  CONCLUIDO: 2,
};

export const EIXO_LIFTBOX = {
  EIXO_VERTICAL: 'EixoVertical',
  EIXO_HORIZONTAL: 'EixoHorizontal',
};

export const LIFTBOX_VIEW = {
  CONFIGURACAO: 1,
  CALIBRACAO: 2,
};

export const LIFTBOX_SNAPSHOT = {
  PRE_CALIBRACAO: 1,
  POS_CALIBRACAO: 2,
};

export const FUNCAO_FORMULA_LIFTBOX = {
  SOMA: 1,
  MEDIA_ARITMETICA: 2,
  MEDIA_PONDERADA: 3,
  COMPONENTE_IGUAL: 4,
};

export const FATOR_FORMULA_LIFTBOX = {
  META: 1,
  RESULTADO_AVALIACAO: 2,
  SECAO_SUBSECAO: 3,
};
export const TIPO_ITENS = {
  INDICADOR: 1,
  PARAMETRO: 2,
  OBJETIVO_ESTRATEGICO: 3,
  PROJETO: 4,
  PROCESSO: 5,
  RISCO: 6,
  REUNIAO: 7,
  CENTRO_CUSTO: 8,
  PACOTE: 9,
  DESEMPENHO_INDIVIDUAL: 10,
  CALCULO_INTERMEDIARIO: 11,
  TEMPLATE_INDICADOR: 12,
  TEMPLATE_PARAMETRO: 13,
  TEMPLATE_OBJETIVO_ESTRATEGICO: 14,
  TEMPLATE_PROJETO: 15,
  ORCAMENTO: 16,
  INDICADOR_RISCO: 17,
  EVENTOS_RISCO: 18,
  AUDITORIA_RISCO: 19,
  CONTROL_SELF_ASSESSMENT: 20,
  AUDITORIA_CHECKLIST: 21,
  GESTAO_DOCUMENTOS: 22,
};

export const STATUS_PONTO_CONTROLE_REVISAO = {
  NAO_INICIADA: 1,
  EM_ANDAMENTO: 2,
  CONCLUIDO: 4,
  EM_VALIDACAO: 5,
  REPROVADO: 6,
};

export const TIPO_COLABORADOR = {
  INTERNO: 1,
  EXTERNO: 2,
};

export const TIPO_GRAFICO_RISCO = {
  COLUNAS: 1,
  COLUNAS_EMPLILHADAS: 2,
  PIZZA: 3,
};

export const LICENSE_KEYS = {
  FUSIONCHARTS:
    'MwC4B-11ftE2F3A8C10A8C7D6D4A3G4C1B6B1lsmF5H2E4eB9B6A7xB-11nA3H2C2hlcB3D1F3xi1j1A4B8E2C4A2A3C2I3G1A7C8goiC4D1e1H3oixF4D5D3idG2OC1E3prcC1G4B1A8D6C4C4G3H3G2A18A16A8B9D-17==',
};

export const CICLO_AVALIACAO_ETAPAS = {
  AVALIACAO: 1,
  AVALIACAO_PARES: 104,
  AVALIACAO_GESTOR_MATRICIAL: 105,
  FEEDBACK: 2,
  PDI: 3,
  CALIBRACAO: 5,
};

export const STATUS_APROVACAO_AVALIACAO = {
  PENDENTE_INDICACAO: 0,
  PENDENTE_APROVACAO: 1,
  APROVADO: 2,
  PENDENTE_SOLICITACAO: 3,
  REPROVADO: 4,
};

export const REUNIAO_GENTE_LISTA = {
  PUBLICO: 0,
  LIDERES: 1,
  CONVIDADOS: 2,
};

export const DASHBOARD_DETALHAMENTO_AVALIACAO_VIEW = {
  TIPO_AVALIACAO: 1,
  COLABORADOR_AREA_EMPRESA: 2,
};

export const TIPO_AVALIACAO_COR = {
  CONSOLIDADO: 'rgb(73, 80, 87, .2)',
  AUTO_AVALIACAO: 'rgb(255, 184, 0, .2)',
  LIDER: 'rgb(4, 77, 186, .2)',
  GESTOR_MATRICIAL: 'rgb(221, 205, 242, .2)',
  LIDERADO: 'rgb(131, 95, 3, .2)',
  PARES: 'rgb(9, 177, 188, .2)',
};

export const DASHBOARD_DETALHAMENTO_AVALIACAO_COLABORADOR_AREA_EMPRESA_BENCHMARK =
  {
    AVALIADO: 1,
    AREA: 2,
    EMPRESA: 3,
    BENCHMARK: 4,
  };

export const DASHBOARD_DETALHAMENTO_AVALIACAO_COLABORADOR_AREA_EMPRESA_BENCHMARK_COR =
  {
    AVALIADO: 'rgb(163,139,150, .2)',
    AREA: 'rgb(215,218,63, .2)',
    EMPRESA: 'rgb(9,177,188, .2)',
    BENCHMARK: 'rgb(0,53,132, .2)',
  };

export const TEMA_REUNIAO_ONE_A_ONE = {
  FEEDBACK: 1,
  CONVERSA_LIVRE: 2,
  CONTRATACAO_DESEMPENHO: 3,
};

export const STATUS_REUNIAO_ONE_A_ONE = {
  AGENDADA: 1,
  EM_ANDAMENTO: 2,
  CONCLUIDA: 3,
};

export const TRANSICAO_BANNER_LIFT = {
  MANUAL: 1,
  AUTOMATICA: 2,
};

export const STATUS_INTEGRACAO_RV_LIFTBOX = {
  AGUARDANDO: 1,
  EM_ANDAMENTO: 2,
};

export const TIPO_CONSOLIDACAO = {
  SOMA: 1,
  MEDIA: 2,
  FORMULA: 3,
  DIGITADO: 4,
  VALOR_EXTERNO: 5,
  VALOR_POWERBI: 6,
};

export const TIPO_CONSOLIDACAO_ACUMULADO = {
  FORMULA: 1,
  IGUAL_PERIODO: 2,
  MEDIA_PERIODO: 3,
  SOMA_PERIODO: 4,
  DIGITADO: 5,
  VALOR_EXTERNO: 6,
  VALOR_POWERBI: 7,
};

export const GANTT_VIEW = {
  GANTT: 'gantt',
  KANBAN: 'kanban',
};

export const ENTIDADE_ANEXOS = {
  PROJETO_STATUS_REPORT: 34,
  EVENTO_RISCO_AVALIACAO: 37,
  EVENTO_RISCO: 38,
  PLANO_CONTINGENCIA: 39,
  PLANO_TESTE_STEP: 44
};

export const STATUS_PROJETO_TAP = {
  PENDENTE: 1,
  AUTORIZADO: 2,
};

export const TIPO_VALOR_PROJETO = {
  CUSTO_REAL: 1,
  VALOR_AGREGADO: 2,
  VALOR_PLANEJADO: 3,
  INDICE_DESEMPENHO_CUSTO: 4,
  INDICE_DESEMPENHO_PRAZO: 5,
  INDICE_DESEMPENHO_ESCOPO: 6,
};

export const TIPO_VALOR = {
  META: 1,
  REALIZADO: 2,
  METAACUMULADO: 4,
  REALIZADOACUMULADO: 5,
};

export const FAROL_ACAO = {
  ROXO: 1,
  AZUL: 2,
  VERDE: 3,
  AMARELO: 4,
  VERMELHO: 5,
  BRANCO: 6,
  PRETO: 7,
};

export const STATUS_ITEM = {
  EM_PLANEJAMENTO: 1,
  ATIVO: 2,
  CONCLUIDO: 3,
  SUSPENSO: 4,
  CANCELADO: 5,
  PENDENTE_CADASTRO: 6,
  REPROVADO: 7,
};

export const TIPO_FORMULA_PORTFOLIO_PROJETOS = {
  SOMA: 1,
  MEDIA: 2,
  MEDIA_PONDERADA: 3,
  PRODUTO: 4,
};

export const STATUS_ACAO = {
  EM_PLANEJAMENTO: 1,
  NAO_INICIADA: 2,
  CONCLUIDA: 3,
  CANCELADA: 4,
  EM_ANDAMENTO: 5,
  ARQUIVADA: 6,
  VALIDACAO: 7,
  PENDENTE_CADASTRO: 8,
  REPROVADA: 9,
};

export const TIPO_ENTIDADE_PRINCIPAL_SOLICITACAO_GESTAO_MUDANCAS = {
  PROJETO: 0,
  INDICADOR: 1,
  PARAMETRO: 2,
  OBJETIVOESTRATEGICO: 3,
  RISCO: 4,
  DOCUMENTO: 5,
};

export const TIPO_MUDANCA_SOLICITACAO_GESTAO_MUDANCAS_PROJETO = {
  PRAZO: 0,
  ESCOPO: 1,
  CUSTO: 2,
};

export const STATUS_SOLICITACAO_GESTAO_MUDANCAS = {
  PENDENTE: 0,
  APROVADA: 1,
  REPROVADA: 2,
  APROVADAPARCIALMENTE: 3,
};

export const STATUS_FLUXO_APROVACAO_GENERICO_VALIDACAO = {
  PENDENTE: 0,
  APROVADA: 1,
  REPROVADA: 2,
  APROVADAPARCIALMENTE: 3,
};

export const EXCEL_COLUMN_CHAR_LIMIT = 32767;

export const TIPO_MUDANCA_SOLICITACAO_GESTAO_MUDANCAS_INDICADOR = {
  REALIZADO: 0,
  META: 1,
  FORMULACALCULO: 2,
};

export const TIPO_MUDANCA_SOLICITACAO_GESTAO_MUDANCAS_PARAMETRO = {
  REALIZADO: 0,
  META: 1,
  FORMULACALCULO: 2,
};

export const TIPO_MUDANCA_SOLICITACAO_GESTAO_MUDANCAS_OBJETIVOESTRATEGICO = {
  REALIZADO: 0,
  META: 1,
  FORMULACALCULO: 2,
};

export const TIPO_INTERACAO_SOPHIE = {
  INSIGHTS_FEEDBACK_PROCESSO_AVALIATIVO: 'InsightsFeedbackProcessoAvaliativo',
  SUGERIR_ACOES_PDI: 'SugerirAcoesPDI',
  SUGERIR_RISCOS_ITENS_IMPACTADOS: 'SugerirRiscosItensImpactados',
  SUGERIR_CAUSA_RISCO: 'SugerirCausaRisco',
  SUGERIR_CONSEQUENCIA_RISCO: 'SugerirConsequenciaRisco',
  INSIGHTS_RISCO: 'InsightsRisco',
  SUGERIR_CONTROLES_RISCO: 'SugerirControlesRisco',
  SUGERIR_RISCO: 'SugerirRisco',
  INSIGHTS_PROJETO: 'InsightsProjeto',
  SUGERIR_ACOES_GANTT: 'SugerirAcoesGantt',
  SUGERIR_OBJETIVOS_OKR: 'SugerirObjetivosOKR',
  SUGERIR_KEY_RESULTS_OKR: 'SugerirKeyResultsOKR',
  SUGERIR_CAUSA_SOLUCAO_PROBLEMAS: 'SugerirCausaSolucaoProblemas',
};

export const TIPO_INTEGRACAO_CONNECTOR = {
  COLABORADOR: 0,
  HISTORICO_CARGO: 1,
  CARGO: 2,
  ORGANIZACAO: 3,
  VALOR_REALIZADO: 4,
};

export const EXCEPTION_CANCELLATION_TOKEN = 'cancelation token';

export const UNKNOWN_PATHNAME = 'pathname-to-reset-everything';

export const STATUS_CESTA_META_INDIVIDUAL = {
  RASCUNHO: 1,
  PENDENTE_APROVACAO: 2,
  APROVADA: 3,
  REPROVADA: 4,
  ATIVA: 5,
  CONCLUIDA: 6,
  EM_REVISAO: 7,
  PENDENTE_VALIDACAO: 8,
};

export const STATUS_CICLO_META_INDIVIDUAL = {
  RASCUNHO: 1,
  REPROVADA: 2,
  ATIVA: 3,
  CANCELADA: 4,
  CONCLUIDA: 5,
};

export const ADDONS = {
  METAS_INDIVIDUAIS: 1,
  COMISSOES: 2,
  CONNECTOR: 3,
  VALOR_POWERBI: 4,
  ELOGIOS: 5,
  AUDITORIA_RISCO: 6,
  FEEDBACK_CONTINUO: 7,
  CONTROL_SELF_ASSESSMENT: 8,
  GESTAO_DOCUMENTOS: 9,
  NEGOCIACAO_METAS: 10,
  TESTE_CONTROLE: 11,
  INTEGRACAO_POWERBI: 12,
};

export const TIPO_CESTA_INDICADOR = {
  BONUS: 1,
  COMISSAO: 2,
};

export const TIPO_GRAFICO_PROJETO = {
  COLUNAS: 1,
};

export const PERIODICIDADE = {
  DIARIA: 1,
  SEMANAL: 2,
  QUINZENAL: 3,
  MENSAL: 4,
  BIMESTRAL: 5,
  TRIMESTRAL: 6,
  QUADRIMESTRAL: 7,
  SEMESTRAL: 8,
  ANUAL: 9,
  CONFORME_CALENDARIO: 10,
  PERSONALIZADA: 11,
};

export const TIPO_MFA = {
  CODIGO_EMAIL: 1,
  AUTHENTICATOR: 2,
};

export const TIPO_OBJETIVO_DESEMPENHO_OKR = {
  AREA: 1,
  PROJETO: 2,
  COLABORADOR: 3,
  OBJETIVO_ESTRATEGICO: 4,
};

export const TIPO_VINCULO_OBJETIVO_OKR = {
  AREA: 1,
  COLABORADOR: 2,
  ITEM: 3,
};

export const ORIGEM_FAIXA_RESULTADO = {
  REMUNERACAO_VARIAVEL: 1,
  AUDITORIA: 2,
};


export const TIPO_LIBERACAO_RESULTADOS = {
  DATA_ATUAL: 1,
  DATA_PROGRAMADA: 2,
  APOS_FEEDBACK: 3,
};
