import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import Dialog from 'src/componentes/dialog';
import useAuth from 'src/hooks/useAuth';
import { getLatestVersion } from 'src/lib/dato-cms';

export default function ChangelogVersion() {
  const { versaoSoftware } = useSelector((state) => state.user);
  const { user } = useAuth();

  const [version, setVersion] = useState(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    getLatestVersion().then((data) => {
      setVersion(data);
    });
  }, []);

  useEffect(() => {
    const lastSeenVersion = localStorage.getItem('lastSeenVersion');
    if (
      !lastSeenVersion ||
      parseFloat(versaoSoftware) > parseFloat(lastSeenVersion)
    ) {
      setIsDialogOpen(true);
    }
  }, [versaoSoftware]);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    localStorage.setItem('lastSeenVersion', versaoSoftware);
  };

  const renderModalTitle = (modal) => {
    switch (user?.idioma) {
      case 'pt-br':
        return modal?.dialogTitlePtBr;
      case 'es':
        return modal?.dialogTitleEs;
      default:
        return modal?.dialogTitleEn;
    }
  };

  const renderModalDescription = (modal) => {
    switch (user?.idioma) {
      case 'pt-br':
        return modal?.dialogDescriptionPtBr;
      case 'es':
        return modal?.dialogDescriptionEs;
      default:
        return modal?.dialogDescriptionEn;
    }
  };

  const renderModalButtonTitle = (modal) => {
    switch (user?.idioma) {
      case 'pt-br':
        return modal?.dialogButtonTitlePtBr;
      case 'es':
        return modal?.dialogButtonTitleEs;
      default:
        return modal?.dialogButtonTitleEn;
    }
  };

  if (!isDialogOpen || !user?.usuarioPerfilAdmin || !version) {
    return null;
  }

  const modal = version?.modal?.[0];

  if (!modal) {
    return null;
  }

  return (
    <Dialog onClose={handleDialogClose}>
      <div className="pb-4 px-4" style={{ width: 650 }}>
        <h1 className="text-3xl font-semibold">{renderModalTitle(modal)}</h1>
        <div
          style={{
            maxHeight: '300px',
            overflowY: 'auto',
            padding: '1rem',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
          }}
        >
          <ReactMarkdown>{renderModalDescription(modal)}</ReactMarkdown>
        </div>

        <hr className="my-4" />

        <div className="d-flex justify-content-end align-items-center mb-3">
          <Link to="/notas-versao">
            <Button
              color="primary"
              className="rounded-lg font-bold"
              onClick={handleDialogClose}
            >
              {renderModalButtonTitle(modal)}
            </Button>
          </Link>
        </div>
      </div>
    </Dialog>
  );
}
