import React from 'react';
import { autobind } from 'core-decorators';
import { Collapse, Card, CardTitle, CardSubtitle } from 'reactstrap';
import MdiIcon from '../mdi-icon';

@autobind
class CardCustom extends React.Component {
  static defaultProps = {
    open: true,
    borderColor: '#cfdbe2',
    overflow: false,
    showDelete: true,
    showNew: true,
    showPage: true,
    showToolBar: true,
    showDetail: false,
    showSearch: true,
    errors: null,
    collapseCallback: () => {},
    hideShadow: false,
    childrenLoaded: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.open !== this.props.open) {
      this.setState({ open: newProps.open });
    }
  }

  handleCollapse() {
    this.props.onCollapse && this.props.onCollapse();
    this.setState(
      {
        open: !this.state.open,
        childrenLoaded: true,
      },
      () => {
        this.props.collapseCallback(this.state.open);
      }
    );
  }

  render() {
    let {
      header,
      children,
      subtitle,
      headerStyle,
      errors,
      hideShadow,
      loadChildrenOnOpen = false,
      style,
    } = this.props;

    return (
      <Card
        body
        style={{
          marginBottom: 15,
          cursor: 'pointer',
          borderColor: errors ? 'red' : null,
          boxShadow: hideShadow ? 'none' : '',
          ...style,
        }}
        className={`${this.props.className} px-4 py-3`}
      >
        {header && (
          <>
            <CardTitle onClick={this.handleCollapse} className={headerStyle}>
              {header}
              <MdiIcon
                icon={this.state.open ? 'chevron-up' : 'chevron-down'}
                className="pull-right"
              />
            </CardTitle>
            {subtitle && (
              <CardSubtitle className="mt-1 text-muted">
                {subtitle}
              </CardSubtitle>
            )}
          </>
        )}
        <Collapse
          isOpen={this.state.open}
          style={{
            marginTop: this.state.open ? 28 : 0,
            transition: '0.3s ease-out',
            cursor: 'default',
          }}
        >
          {!loadChildrenOnOpen || this.state.open || this.state.childrenLoaded
            ? children
            : null}
        </Collapse>
      </Card>
    );
  }
}

export default CardCustom;
