const API_URL = 'https://graphql.datocms.com/';
const API_TOKEN = process.env.REACT_APP_DATOCMS_READ_ONLY_API_TOKEN;

async function fetchCmsAPI(query, { variables } = {}) {
  const res = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${API_TOKEN}`,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  const json = await res.json();
  if (json.errors) {
    throw new Error('Failed to fetch API');
  }

  return json.data;
}

export async function getLatestVersion() {
  const data = await fetchCmsAPI(`
    {
      allVersionNotes(
        first: "1"
        orderBy: versionLaunchDate_DESC
        filter: {versionVisible: {eq: true}}
      ) {
        id
        versionLaunchDate
        versionNumber
        versionVisible
        versionAssetPtBr {
          id
          url
          filename
        }
        versionAssetEs {
          id
          url
          filename
        }
        versionAssetEn {
          id
          url
          filename
        }
        activities {
          id
          activityTitlePtBr
          activityDescriptionPtBr
          activityTitleEn
          activityDescriptionEn
          activityTitleEs
          activityDescriptionEs
          activityType
          activityModules {
            id
            nome
            sigla
            imagem {
              id
              url
            }
          }
        }
          modal {
          id
          dialogTitlePtBr
          dialogDescriptionPtBr
          dialogButtonTitlePtBr
          dialogTitleEn
          dialogDescriptionEn
          dialogButtonTitleEn
          dialogTitleEs
          dialogDescriptionEs
          dialogButtonTitleEs
        }
      }
    }
  `);
  return data.allVersionNotes[0];
}

export async function getAllVersions(modules) {
  const data = await fetchCmsAPI(`
    {
      allVersionNotes(
        first: "100"
        orderBy: versionLaunchDate_DESC
        filter: {versionVisible: {eq: true}}
      ) {
        id
        versionLaunchDate
        versionNumber
        versionVisible
        versionAssetPtBr {
          id
          url
          filename
        }
        versionAssetEs {
          id
          url
          filename
        }
        versionAssetEn {
          id
          url
          filename
        }
        activities {
          id
          activityTitlePtBr
          activityDescriptionPtBr
          activityTitleEn
          activityDescriptionEn
          activityTitleEs
          activityDescriptionEs
          activityType
          activityModules {
            id
            nome
            sigla
            imagem {
              id
              url
            }
          }
        }
      }
    }
  `);

  const modulesSet = new Set(modules);
  const versions = data.allVersionNotes
    // .filter((v) => {
    //   return v.activities.some((a) =>
    //     a.activityModules.some((m) => modulesSet.has(m.id))
    //   );
    // })
    .map((v) => {
      v.activities = v.activities.filter((a) =>
        a.activityModules.some((m) => modulesSet.has(m.id))
      );
      return v;
    });

  return versions;
}

export async function getAllModules() {
  const data = await fetchCmsAPI(`
    {
      allModulos(filter: {visivel: {eq: "true"}}) {
        id
        nome
        sigla
        codigo
      }
    }
  `);

  return data.allModulos;
}
